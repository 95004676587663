
import React from 'react'

const Acc1 = React.lazy(() => import('./components/Acc1.js'))
const Acc2 = React.lazy(() => import('./components/Acc2.js'))
const Acc3 = React.lazy(() => import('./components/Acc3.js'))
const Bodytatoo1 = React.lazy(() => import('./components/Bodytatoo1.js'))
const Body = React.lazy(() => import('./components/Body.js'))
const Eye1 = React.lazy(() => import('./components/Eye1.js'))
const Eye2 = React.lazy(() => import('./components/Eye2.js'))
const Eye3 = React.lazy(() => import('./components/Eye3.js'))
const Eye4 = React.lazy(() => import('./components/Eye4.js'))
const Face1 = React.lazy(() => import('./components/Face1.js'))
const Face2 = React.lazy(() => import('./components/Face2.js'))
const Face3 = React.lazy(() => import('./components/Face3.js'))
const Face4 = React.lazy(() => import('./components/Face4.js'))
const Floor = React.lazy(() => import('./components/Floor.js'))
const Glasses1 = React.lazy(() => import('./components/Glasses1.js'))
const Glasses2 = React.lazy(() => import('./components/Glasses2.js'))
const Glasses3 = React.lazy(() => import('./components/Glasses3.js'))
const Glasses4 = React.lazy(() => import('./components/Glasses4.js'))
const Glasses5 = React.lazy(() => import('./components/Glasses5.js'))
const Glasses6 = React.lazy(() => import('./components/Glasses6.js'))
const Hair1 = React.lazy(() => import('./components/Hair1.js'))
const Hair2 = React.lazy(() => import('./components/Hair2.js'))
const Hair3 = React.lazy(() => import('./components/Hair3.js'))
const Hair4 = React.lazy(() => import('./components/Hair4.js'))
const Hair5 = React.lazy(() => import('./components/Hair5.js'))
const Hait3 = React.lazy(() => import('./components/Hait3.js'))
const Hat1 = React.lazy(() => import('./components/Hat1.js'))
const Hat10 = React.lazy(() => import('./components/Hat10.js'))
const Hat2 = React.lazy(() => import('./components/Hat2.js'))
const Hat3 = React.lazy(() => import('./components/Hat3.js'))
const Hat4 = React.lazy(() => import('./components/Hat4.js'))
const Hat5 = React.lazy(() => import('./components/Hat5.js'))
const Hat6 = React.lazy(() => import('./components/Hat6.js'))
const Hat7 = React.lazy(() => import('./components/Hat7.js'))
const Hat8 = React.lazy(() => import('./components/Hat8.js'))
const Hat9 = React.lazy(() => import('./components/Hat9.js'))
const Head = React.lazy(() => import('./components/Head.js'))
const Mouth1 = React.lazy(() => import('./components/Mouth1.js'))
const Mouth2 = React.lazy(() => import('./components/Mouth2.js'))
const Mouth3 = React.lazy(() => import('./components/Mouth3.js'))
const Mouth4 = React.lazy(() => import('./components/Mouth4.js'))
const Wear1 = React.lazy(() => import('./components/Wear1.js'))
const Wear2 = React.lazy(() => import('./components/Wear2.js'))
const Wear3 = React.lazy(() => import('./components/Wear3.js'))
const Wear4 = React.lazy(() => import('./components/Wear4.js'))
const Wear5 = React.lazy(() => import('./components/Wear5.js'))
const Wear6 = React.lazy(() => import('./components/Wear6.js'))
const Wear7 = React.lazy(() => import('./components/Wear7.js'))

const Acc1Icon = React.lazy(() => import('./components/Acc1Icon.js'))
const Acc2Icon = React.lazy(() => import('./components/Acc2Icon.js'))
const Acc3Icon = React.lazy(() => import('./components/Acc3Icon.js'))
const Bodytatoo1Icon = React.lazy(() => import('./components/Bodytatoo1Icon.js'))
const BodyIcon = React.lazy(() => import('./components/BodyIcon.js'))
const Eye1Icon = React.lazy(() => import('./components/Eye1Icon.js'))
const Eye2Icon = React.lazy(() => import('./components/Eye2Icon.js'))
const Eye3Icon = React.lazy(() => import('./components/Eye3Icon.js'))
const Eye4Icon = React.lazy(() => import('./components/Eye4Icon.js'))
const Face1Icon = React.lazy(() => import('./components/Face1Icon.js'))
const Face2Icon = React.lazy(() => import('./components/Face2Icon.js'))
const Face3Icon = React.lazy(() => import('./components/Face3Icon.js'))
const Face4Icon = React.lazy(() => import('./components/Face4Icon.js'))
const FloorIcon = React.lazy(() => import('./components/FloorIcon.js'))
const Glasses1Icon = React.lazy(() => import('./components/Glasses1Icon.js'))
const Glasses2Icon = React.lazy(() => import('./components/Glasses2Icon.js'))
const Glasses3Icon = React.lazy(() => import('./components/Glasses3Icon.js'))
const Glasses4Icon = React.lazy(() => import('./components/Glasses4Icon.js'))
const Glasses5Icon = React.lazy(() => import('./components/Glasses5Icon.js'))
const Glasses6Icon = React.lazy(() => import('./components/Glasses6Icon.js'))
const Hair1Icon = React.lazy(() => import('./components/Hair1Icon.js'))
const Hair2Icon = React.lazy(() => import('./components/Hair2Icon.js'))
const Hair3Icon = React.lazy(() => import('./components/Hair3Icon.js'))
const Hair4Icon = React.lazy(() => import('./components/Hair4Icon.js'))
const Hair5Icon = React.lazy(() => import('./components/Hair5Icon.js'))
const Hait3Icon = React.lazy(() => import('./components/Hait3Icon.js'))
const Hat1Icon = React.lazy(() => import('./components/Hat1Icon.js'))
const Hat10Icon = React.lazy(() => import('./components/Hat10Icon.js'))
const Hat2Icon = React.lazy(() => import('./components/Hat2Icon.js'))
const Hat3Icon = React.lazy(() => import('./components/Hat3Icon.js'))
const Hat4Icon = React.lazy(() => import('./components/Hat4Icon.js'))
const Hat5Icon = React.lazy(() => import('./components/Hat5Icon.js'))
const Hat6Icon = React.lazy(() => import('./components/Hat6Icon.js'))
const Hat7Icon = React.lazy(() => import('./components/Hat7Icon.js'))
const Hat8Icon = React.lazy(() => import('./components/Hat8Icon.js'))
const Hat9Icon = React.lazy(() => import('./components/Hat9Icon.js'))
const HeadIcon = React.lazy(() => import('./components/HeadIcon.js'))
const Mouth1Icon = React.lazy(() => import('./components/Mouth1Icon.js'))
const Mouth2Icon = React.lazy(() => import('./components/Mouth2Icon.js'))
const Mouth3Icon = React.lazy(() => import('./components/Mouth3Icon.js'))
const Mouth4Icon = React.lazy(() => import('./components/Mouth4Icon.js'))
const Wear1Icon = React.lazy(() => import('./components/Wear1Icon.js'))
const Wear2Icon = React.lazy(() => import('./components/Wear2Icon.js'))
const Wear3Icon = React.lazy(() => import('./components/Wear3Icon.js'))
const Wear4Icon = React.lazy(() => import('./components/Wear4Icon.js'))
const Wear5Icon = React.lazy(() => import('./components/Wear5Icon.js'))
const Wear6Icon = React.lazy(() => import('./components/Wear6Icon.js'))
const Wear7Icon = React.lazy(() => import('./components/Wear7Icon.js'))

export const getAsset = (name) => {
if (name === 'acc-1') return Acc1
if (name === 'acc-2') return Acc2
if (name === 'acc-3') return Acc3
if (name === 'body-tatoo-1') return Bodytatoo1
if (name === 'body') return Body
if (name === 'eye-1') return Eye1
if (name === 'eye-2') return Eye2
if (name === 'eye-3') return Eye3
if (name === 'eye-4') return Eye4
if (name === 'face-1') return Face1
if (name === 'face-2') return Face2
if (name === 'face-3') return Face3
if (name === 'face-4') return Face4
if (name === 'floor') return Floor
if (name === 'glasses-1') return Glasses1
if (name === 'glasses-2') return Glasses2
if (name === 'glasses-3') return Glasses3
if (name === 'glasses-4') return Glasses4
if (name === 'glasses-5') return Glasses5
if (name === 'glasses-6') return Glasses6
if (name === 'hair-1') return Hair1
if (name === 'hair-2') return Hair2
if (name === 'hair-3') return Hair3
if (name === 'hair-4') return Hair4
if (name === 'hair-5') return Hair5
if (name === 'hait-3') return Hait3
if (name === 'hat-1') return Hat1
if (name === 'hat-10') return Hat10
if (name === 'hat-2') return Hat2
if (name === 'hat-3') return Hat3
if (name === 'hat-4') return Hat4
if (name === 'hat-5') return Hat5
if (name === 'hat-6') return Hat6
if (name === 'hat-7') return Hat7
if (name === 'hat-8') return Hat8
if (name === 'hat-9') return Hat9
if (name === 'head') return Head
if (name === 'mouth-1') return Mouth1
if (name === 'mouth-2') return Mouth2
if (name === 'mouth-3') return Mouth3
if (name === 'mouth-4') return Mouth4
if (name === 'wear-1') return Wear1
if (name === 'wear-2') return Wear2
if (name === 'wear-3') return Wear3
if (name === 'wear-4') return Wear4
if (name === 'wear-5') return Wear5
if (name === 'wear-6') return Wear6
if (name === 'wear-7') return Wear7
}

export const getIcon = (name) => {
if (name === 'acc-1') return Acc1Icon
if (name === 'acc-2') return Acc2Icon
if (name === 'acc-3') return Acc3Icon
if (name === 'body-tatoo-1') return Bodytatoo1Icon
if (name === 'body') return BodyIcon
if (name === 'eye-1') return Eye1Icon
if (name === 'eye-2') return Eye2Icon
if (name === 'eye-3') return Eye3Icon
if (name === 'eye-4') return Eye4Icon
if (name === 'face-1') return Face1Icon
if (name === 'face-2') return Face2Icon
if (name === 'face-3') return Face3Icon
if (name === 'face-4') return Face4Icon
if (name === 'floor') return FloorIcon
if (name === 'glasses-1') return Glasses1Icon
if (name === 'glasses-2') return Glasses2Icon
if (name === 'glasses-3') return Glasses3Icon
if (name === 'glasses-4') return Glasses4Icon
if (name === 'glasses-5') return Glasses5Icon
if (name === 'glasses-6') return Glasses6Icon
if (name === 'hair-1') return Hair1Icon
if (name === 'hair-2') return Hair2Icon
if (name === 'hair-3') return Hair3Icon
if (name === 'hair-4') return Hair4Icon
if (name === 'hair-5') return Hair5Icon
if (name === 'hait-3') return Hait3Icon
if (name === 'hat-1') return Hat1Icon
if (name === 'hat-10') return Hat10Icon
if (name === 'hat-2') return Hat2Icon
if (name === 'hat-3') return Hat3Icon
if (name === 'hat-4') return Hat4Icon
if (name === 'hat-5') return Hat5Icon
if (name === 'hat-6') return Hat6Icon
if (name === 'hat-7') return Hat7Icon
if (name === 'hat-8') return Hat8Icon
if (name === 'hat-9') return Hat9Icon
if (name === 'head') return HeadIcon
if (name === 'mouth-1') return Mouth1Icon
if (name === 'mouth-2') return Mouth2Icon
if (name === 'mouth-3') return Mouth3Icon
if (name === 'mouth-4') return Mouth4Icon
if (name === 'wear-1') return Wear1Icon
if (name === 'wear-2') return Wear2Icon
if (name === 'wear-3') return Wear3Icon
if (name === 'wear-4') return Wear4Icon
if (name === 'wear-5') return Wear5Icon
if (name === 'wear-6') return Wear6Icon
if (name === 'wear-7') return Wear7Icon
}

export const assetNames = ['acc-1','acc-2','acc-3','body-tatoo-1','body','eye-1','eye-2','eye-3','eye-4','face-1','face-2','face-3','face-4','floor','glasses-1','glasses-2','glasses-3','glasses-4','glasses-5','glasses-6','hair-1','hair-2','hair-3','hair-4','hair-5','hait-3','hat-1','hat-10','hat-2','hat-3','hat-4','hat-5','hat-6','hat-7','hat-8','hat-9','head','mouth-1','mouth-2','mouth-3','mouth-4','wear-1','wear-2','wear-3','wear-4','wear-5','wear-6','wear-7']
